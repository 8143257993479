@tailwind base;
@tailwind components;
@tailwind utilities;

/* global styles go here */
body,
html,
#__next {
  @apply h-full scroll-smooth lg:overscroll-none;
}

#__next > footer {
  top: 100vh;
  position: sticky;
}

/* Text styles */
h1,
h2,
h3,
h4,
h6 {
  font-family: theme("fontFamily.primary");
}

h5,
p,
* {
  font-family: theme("fontFamily.secondary");
}

h1 {
  @apply text-m2 sm:text-l1 lg:text-xl1 uppercase;
}

h2 {
  @apply text-l1 lg:text-l2;
}

h3 {
  @apply text-m3 lg:text-l1;
}
h4 {
  @apply text-m1 lg:text-m2;
}
h5 {
  @apply text-m1 font-bold;
}
h6 {
  @apply text-base;
}

li p {
  line-height: theme("spacing.7");
}

/* End Text styles */

/* Call to Action */
.secondary-link-purple::after,
.secondary-link-navy::after {
  content: "";
  height: 104%;
  aspect-ratio: 1/1;
  position: absolute;
  right: -48px;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  transition: all;
  animation-duration: 300ms;
  border-style: solid;
  border-width: 1px;
  @apply transition-all duration-300;
}

.secondary-link-purple::after {
  background: no-repeat center / 16px 8px url("public/assets/purple-arrow.svg");
  border-color: theme("colors.purple");
}
.secondary-link-navy::after {
  background: no-repeat center / 16px 8px url("public/assets/navy-arrow.svg");
  border-color: theme("colors.navy");
}

.secondary-link-purple:hover::after {
  background-image: url("public/assets/white-arrow.svg");
  background-color: theme("colors.purple");
}
.secondary-link-navy:hover::after {
  background-image: url("public/assets/white-arrow.svg");
  background-color: theme("colors.navy");
}

.phone-link,
.map-pin-link,
.fax-link,
.share-link {
  display: flex;
  gap: theme("spacing.1");
  align-items: center;
  width: max-content;
}
.phone-link::before,
.map-pin-link::before,
.fax-link::before,
.share-link::after {
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.phone-link::before {
  background-image: url("public/assets/team-member-phone.svg");
  width: 18px;
  height: 18px;
}
.map-pin-link::before {
  background-image: url("public/assets/map-pin.svg");
  width: 18px;
  height: 18px;
}

.fax-link::before {
  background-image: url("public/assets/fax.svg");
  width: 18px;
  height: 18px;
}
.share-link::after {
  background-image: url("public/assets/share.svg");
  margin-top: 4px;
  width: 11px;
  height: 11px;
}
/* End Call to Action */

/* Blog */
.plain-navy-arrow {
  margin-right: 12px;
}

.plain-navy-arrow::after {
  content: "";
  width: 16px;
  height: 8px;
  display: inline-block;
  transform: translate(3px, -40%);
  @apply transition-all duration-300;
  background: no-repeat center / 16px 8px url("public/assets/navy-arrow.svg");
}

.plain-navy-arrow:hover::after {
  transform: translate(5px, -40%);
}

.plain-purple-arrow::after {
  content: "";
  width: 16px;
  height: 8px;
  position: relative;
  padding-left: 1px;
  transform: translate(3px, 85%);
  display: inline-block;
  @apply transition-all duration-300;
  background: no-repeat center / 16px 8px url("public/assets/purple-arrow.svg");
}

.plain-purple-arrow:hover::after {
  transform: translate(5px, 85%);
}

/* End Blog */

/* Main Menu */
/* Header */
/* Current Page */
.menu ul li ul li .current-page {
  text-decoration: underline;
}

/* Top Level Menu Items */
.menu > ul > li {
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: white;
  font-weight: 600;

  @apply xl:my-0;
}

.menu ul li a,
.menu ul li .nonlinked {
  width: max-content;
}

.menu > ul > li > a:hover {
  text-decoration-line: underline;
}

.menu > ul > li > .nonlinked:hover {
  cursor: pointer;
  text-decoration-line: underline;
  @apply xl:cursor-default;
}

/* Second Level Menu Items */
.menu > ul > li > ul {
  background-color: theme("colors.blue.light");
  padding: 0px 32px;
  margin-left: -2rem;
  margin-right: -2rem;
  overflow: hidden;
  transition: max-height 500ms ease-out;
  height: auto;
  max-height: 800px;

  @apply xl:-mx-0 xl:block xl:absolute xl:scale-y-0 xl:transition-all xl:duration-500 xl:origin-top xl:border-t-[36px] xl:border-t-navy xl:overflow-visible;
}

.menu > ul > li > ul.closed {
  max-height: 0;

  @apply xl:max-h-max;
}

.menu > ul > li:hover > ul,
.menu > ul > li > ul:focus-within {
  @apply xl:block xl:scale-y-100;
}

.menu ul li ul li {
  color: theme("colors.purple");
  border-bottom: 0.5px solid theme("colors.purple");
  text-transform: uppercase;
  position: relative;
  font-family: theme("fontFamily.primary");
  font-weight: 400;
  padding: 1.5rem 0rem;
  background-color: theme("colors.blue.light");

  @apply last:border-b-0 xl:px-0;
}

.menu ul li ul li a,
.menu ul li ul li .nonlinked {
  font-family: theme("fontFamily.primary");
}

.menu > ul > li > ul > li > a {
  margin-right: 24px;
  position: relative;
}

.menu > ul > li > ul > li > a::after {
  content: "";
  background: no-repeat center / 16px 8px url("public/assets/purple-arrow.svg");
  height: 8px;
  width: 16px;
  margin-left: 8px;
  display: inline-block;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  transition: all;
  transition-duration: 300ms;
}

.menu > ul > li > ul > li > a:hover::after {
  margin-left: 12px;
}
/* Third Level Menu Items */
.menu > ul > li > ul > li > ul {
  margin-top: 1rem;

  @apply xl:grid xl:grid-cols-[repeat(3,max-content)] xl:gap-x-8;
}
.menu ul li ul li ul li {
  color: theme("colors.navy");
  display: block;
  text-transform: capitalize;
  border-width: 0px;
  font-weight: 300;
  font-family: theme("fontFamily.secondary");
  padding: 0.25rem 0rem;
}

.menu ul li ul li ul li a,
.menu ul li ul li ul li .nonlinked {
  font-family: theme("fontFamily.secondary");
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.menu ul li ul li ul li .nonlinked {
  font-weight: 600;
}

.menu ul li ul li ul li a {
  font-weight: 300;
  margin-right: 12px;
}

.menu ul li ul li ul li a::after {
  content: "›";
  height: 8px;
  width: 16px;
  margin-left: 4px;
  position: absolute;
  display: inline-block;
  transition-property: all;
  transition-duration: 300ms;
}

.menu ul li ul li ul li a:hover::after {
  margin-left: 6px;
}

.menu ul li ul li ul li .nonlinked {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  font-weight: 600;
}

.menu ul li ul li ul li ul li a {
  font-weight: 300;
}

/* mobile menu button */
.mobile-menu-btn.open .closed-lines,
.mobile-menu-btn .open-lines {
  display: none;
}

.mobile-menu-btn.open .open-lines {
  display: block;
}
/* End mobile menu button */

/* Header logo animation */
.header-logo .logo,
.header-logo .sublogo {
  opacity: 0;
  animation: fadeIn 4s linear forwards;
}
.header-logo .logo {
  animation: fadeIn 1s linear forwards;
}
.header-logo .sublogo {
  animation: fadeIn 1s linear forwards;
  animation-delay: 1s;
}

@keyframes fadeIn {
  to {
    opacity: 100;
  }
}
/* End Header logo animation */
/* End Header */

/* CTA Banner */
#__next:has(.cta-banner-section),
.page-content:has(.cta-banner-section) {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.cta-banner-section {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
}

.cta-banner {
  height: 50px;
  margin-bottom: -2px;
}

@screen lg {
  .cta-banner {
    height: 146px;
  }
}

/* End CTA Banner */

/* Footer */
.footer-grid {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-template-areas:
    "logo"
    "phone"
    "social"
    "quick-links"
    "locations";
}
@screen md {
  .footer-grid {
    grid-template-columns: 170px 30% 1fr;
    gap: 0px 12px;
    grid-template-areas:
      "logo logo logo"
      "phone quick-links locations"
      "social quick-links locations";
  }
}
@screen lg {
  .footer-grid {
    grid-template-columns: 0.7fr 1fr 1fr;
    grid-template-areas:
      "logo quick-links locations"
      "phone quick-links locations"
      "phone quick-links locations"
      "social quick-links locations";
  }
}
.footer-logo {
  grid-area: logo;
}

.footer-phone-numbers {
  grid-area: phone;
}

.footer-social-icons {
  grid-area: social;
}

#footer-quick-links {
  grid-area: quick-links;

  @apply md:justify-self-center;
}
#footer-locations {
  grid-area: locations;
}

.icon:hover .social-icon {
  fill: #72226d;
}

/* End Footer */

/* Content Component */
.content-component-grid {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-template-areas:
    "content"
    "testimonials"
    "cta-banner";
}

@screen lg {
  .content-component-grid {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
      "content content cta-banner"
      "testimonials testimonials testimonials";
  }
}

.content-component {
  grid-area: content;
}

.testimonials {
  grid-area: testimonials;
}

.content-cta-banner {
  grid-area: cta-banner;
}

/* End Content Component */

/* Homepage Map */
.home-map-thin-waves {
  display: none;
}

@screen lg {
  .home-map-thin-waves {
    display: block;
    animation: 15s flip ease-in-out alternate infinite;
    transform-origin: left center;
  }
}

/* End Homepage Map */

/* Image and Text Hero */
.path-home-hero {
  clip-path: url(#homeHeroSmall);
}
@screen lg {
  .path-home-hero {
    clip-path: url(#homeHeroLarge);
  }
}
/* End Image and Text Hero */

/* Miscellaneous Style Fixes */
.content li p {
  @apply py-1;
}
b {
  font-weight: 700;
}
/* End Miscellaneous Style Fixes */

/* Pull quote */
.blue-quote,
.light-blue-quote {
  position: relative;
  z-index: 0;
}

.blue-quote::before {
  content: url("public/assets/blue-quote-mark.svg");
  position: absolute;
  top: 12px;
  left: -24px;
  transform: rotate(180deg);
}
.blue-quote::after {
  content: url("public/assets/blue-quote-mark.svg");
  position: absolute;
  bottom: 12px;
  right: -24px;
}
.light-blue-quote::before {
  content: url("public/assets/light-blue-quote.svg");
  position: absolute;

  @apply -top-12 -left-20 lg:-top-6 lg:-left-12 scale-50 sm:scale-75 lg:scale-100;
}
.light-blue-quote::after {
  content: url("public/assets/light-blue-quote.svg");
  position: absolute;

  @apply absolute rotate-180 -bottom-12 -right-20 lg:-bottom-6 lg:-right-12 scale-50 sm:scale-75 lg:scale-100;
}
/* End Pull quote */

/* Image Card Section */
@screen lg {
  .image-card:nth-child(2n) {
    padding-right: 0;
    border-left: 1px solid theme("colors.blue.DEFAULT");
  }
  .image-card:nth-child(2n + 1) {
    padding-left: 0;
  }
  .image-card:last-child:nth-child(2n + 1) {
    padding-left: 3rem;
  }
}

@screen xl {
  .image-card:nth-child(2n) {
    padding-right: 3rem;
  }
  .image-card:nth-child(2n + 1) {
    padding-left: 3rem;
    border-left: 1px solid theme("colors.blue.DEFAULT");
  }

  .image-card:nth-child(3n) {
    padding-right: 0;
  }
  .image-card:nth-child(3n + 1) {
    padding-left: 0;
    border: none;
  }
}
/* End Image Card Section */

/* Two Column List */
h3 + ul {
  break-inside: avoid;
  padding-bottom: theme("spacing.2");
}
/* End Two Column List */

/* FAQ Section */
@screen lg {
  .faq-grid {
    display: grid;
    gap: 0px theme("spacing.8");
    grid-template-columns: 3fr 1fr;
    grid-template-areas:
      "heading categories"
      "faqs categories";
  }

  .faq-grid h2 {
    grid-area: heading;
  }

  .faq-grid nav {
    grid-area: categories;
    justify-self: end;
  }

  .faq-grid .faq-section-container {
    grid-area: faqs;
  }
}
/* End FAQ Section */

/* Individual Team Member Page */
.team-member-grid {
  grid-template-areas:
    "bio content"
    "news content";
  grid-template-columns: 345px 3fr;
  grid-template-rows: auto 1fr;
}

.team-member-bio {
  grid-area: bio;
}

.team-member-content {
  grid-area: content;
}

.in-the-news {
  grid-area: news;
}
/* End Individual Team Member Page */

/* Animations */
@keyframes pulse {
  from {
    opacity: 0.9;
  }

  to {
    opacity: 1;
  }
}

@keyframes flip {
  from {
    transform: scaleY(1);
  }

  to {
    transform: scaleY(0.8);
  }
}

@media (prefers-reduced-motion) {
  .home-map-thin-waves {
    animation: none;
  }
}

.pulse {
  opacity: 0;
  animation: 18s pulse ease-out alternate infinite;
}

/* End Animations */
